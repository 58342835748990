import React, { useContext, useState } from 'react'
import videojs from 'video.js'
import 'videojs-contrib-ads/dist/videojs-contrib-ads.css'
import qualityLevels from 'videojs-contrib-quality-levels'
import hlsQualitySelector from 'videojs-hls-quality-selector'
import './videoJsStyle.css'
import './customVideoJs.css'
import ads from 'videojs-contrib-ads'
import { AdSecondsConvert, getAdUrl } from '../../utils/helper'
import { AppContext } from '../../context/app'
import SkipIcon from '../../assets/skip-ad-arrow.svg'
import { isEmpty } from 'lodash'
import { useInterval } from '../../hooks/useInterval'
import useTemplateHook from '../template/templateHook'
import NextIcon from '../../assets/player/next-icon.svg'
import PlayIcon from '../../assets/player/yt-play-icon.svg'
import PauseIcon from '../../assets/player/yt-pause-icon.svg'
import SettingsIcon from '../../assets/player/setting-icon.svg'
import FullscreenEnterIcon from '../../assets/player/fullscreen-enter-icon.svg'
import FullscreenExitIcon from '../../assets/player/fullscreen-exit-icon.svg'
import PIPIcon from '../../assets/player/picture-inpicture-icon.svg'
import CaptureIcon from '../../assets/player/capture-icon.svg'
import VolFullIcon from '../../assets/player/volume-full-icon.svg'
import VolLowIcon from '../../assets/player/volume-low-icon.svg'
import VolZeroIcon from '../../assets/player/volume-zero-icon.svg'
import VolMuteIcon from '../../assets/player/volume-mute-icon.svg'
import CentredPlayIcon from '../../assets/light-play-icon.svg'
import RedCircle from '../../assets/red_circle.gif'
import { convertVideoSecondstoMintues } from './playerHelper'
import SettingsMenu from './settingsMenu'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

let SKIP_SECONDS = 0

const CustomVideoJs = (props) => {
  const { t } = useTranslation()
  const {
    options,
    onReady,
    controlBar,
    adSetData,
    isLive,
    isVODLive,
    handleNext = null,
    handlePrev = null,
  } = props
  const { accountObj } = useContext(AppContext)
  const [skipSeconds, setSkipSeconds] = useState(0)
  const [adDuration, setAdDuration] = useState(0)
  const [currentPlayingAd, setCurrentPlayingAd] = useState({})
  const { customNavigate } = useTemplateHook()

  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)

  const [duration, setDuration] = useState(null)
  const [currentTime, setCurrentTime] = useState(null)
  const [bufferPercent, setBufferPercent] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [volume, setVolume] = useState(100)
  const [isMute, setIsMute] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isPIPMode, setIsPIPMode] = useState(false)
  const [seekBarHoverPercent, setSeekBarHoverPercent] = useState(0)
  const [showVolume, setShowVolume] = useState(false)
  const [showSettingMenu, setShowSettingMenu] = useState(false)
  const [showControlBar, setShowControlBar] = useState(false)
  const [cursorOverControlBar, setCursorOverControlBar] = useState(false)

  React.useEffect(() => {
    videojs.registerPlugin('qualityLevels', qualityLevels)
    videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector)
    videojs.registerPlugin('ads', ads)
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return
      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player)
        handlePlayerReady(player)
        setShowControlBar(true)
      }))

      if (isLive || isVODLive) {
        return
      }

      if (!isEmpty(adSetData)) {
        player.ads()
      }

      player.on('contentchange', () => {
        player.trigger('adsready')
      })

      //   player.controls(false)
      for (let i = 0; i < adSetData.length; i++) {
        const adInfo = adSetData[i]

        if (adInfo.ad_location_type === 'pre') {
          playPreRoll(adInfo)
        }
        if (adInfo.ad_location_type === 'post') {
          playPostRoll(adInfo)
        }
        if (adInfo.ad_location_type === 'mid') {
          playMidRoll(adInfo)
        }
        if (adInfo.ad_location_type === 'custom') {
          playCustomRoll(adInfo)
        }
      }

      player.trigger('adsready')
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef, adSetData])

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  const playPreRoll = (adInfo) => {
    if (!playerRef.current) return
    const player = playerRef.current
    player.on('readyforpreroll', function () {
      player.ads.startLinearAdMode()
      // player.controls(false)
      if (adInfo?.source_asset_id) {
        player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
      }

      // send event when ad is playing to remove loading spinner
      player.one('adplaying', function () {
        commonAdPlayChanges(adInfo, player)

        // player.play()
        // player.muted(false)

        if (adInfo?.is_skip_allowed) {
          setSkipSeconds(adInfo.skip_duration)
          SKIP_SECONDS = adInfo.skip_duration

          let secondInterval = setInterval(() => {
            if (SKIP_SECONDS > 0) {
              setSkipSeconds((prev) => prev - 1)
              SKIP_SECONDS = SKIP_SECONDS - 1
            }
            if (SKIP_SECONDS === 0) {
              clearInterval(secondInterval)
              return
            }
          }, 1000)
        }
      })

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode()
        // player.controls(true)
        setCurrentPlayingAd({})
      })
    })
  }

  const playMidRoll = (adInfo) => {
    if (!playerRef.current) return
    let midRollPlayed = false

    const player = playerRef.current

    player.on('timeupdate', function () {
      let currentTime = Math.floor(player.currentTime())
      let halfLength = Math.floor(player.duration() / 2)
      if (!midRollPlayed && currentTime >= halfLength) {
        player.ads.startLinearAdMode()
        // player.controls(false)
        if (adInfo?.source_asset_id) {
          player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
        }

        // send event when ad is playing to remove loading spinner
        player.one('adplaying', function () {
          midRollPlayed = true
          commonAdPlayChanges(adInfo, player)

          // player.play()
          // player.muted(false)

          if (adInfo?.is_skip_allowed) {
            setSkipSeconds(adInfo.skip_duration)
            SKIP_SECONDS = adInfo.skip_duration

            let secondInterval = setInterval(() => {
              if (SKIP_SECONDS > 0) {
                setSkipSeconds((prev) => prev - 1)
                SKIP_SECONDS = SKIP_SECONDS - 1
              }
              if (SKIP_SECONDS === 0) {
                clearInterval(secondInterval)
                return
              }
            }, 1000)
          }
        })

        // resume content when all your linear ads have finished
        player.on('adended', function () {
          player.ads.endLinearAdMode()
          // player.controls(true)
          setCurrentPlayingAd({})
        })
      }
      // player.duration()
    })
  }

  const playPostRoll = (adInfo) => {
    if (!playerRef.current) return
    const player = playerRef.current
    player.on('readyforpostroll', function () {
      player.ads.startLinearAdMode()
      // player.controls(false)
      if (adInfo?.source_asset_id) {
        player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
      }

      // send event when ad is playing to remove loading spinner
      player.one('adplaying', function () {
        commonAdPlayChanges(adInfo, player)
        // player.muted(false)
        // player.play()
        if (adInfo?.is_skip_allowed) {
          setSkipSeconds(adInfo.skip_duration)
          SKIP_SECONDS = adInfo.skip_duration

          let secondInterval = setInterval(() => {
            if (SKIP_SECONDS > 0) {
              setSkipSeconds((prev) => prev - 1)
              SKIP_SECONDS = SKIP_SECONDS - 1
            }
            if (SKIP_SECONDS === 0) {
              clearInterval(secondInterval)
              return
            }
          }, 1000)
        }
      })

      // resume content when all your linear ads have finished
      player.on('adended', function () {
        player.ads.endLinearAdMode()
        // player.controls(true)
        setCurrentPlayingAd({})
      })
    })
  }

  const playCustomRoll = (adInfo) => {
    if (!playerRef.current) return
    let customRollPlayed = false

    const player = playerRef.current

    player.on('timeupdate', function () {
      let currentTime = Math.floor(player.currentTime())
      let playTime = Math.floor((player.duration() * parseInt(adInfo.ad_location_percentage)) / 100)

      if (!customRollPlayed && currentTime >= playTime) {
        player.ads.startLinearAdMode()
        // player.controls(false)
        if (adInfo?.source_asset_id) {
          player.src(getAdUrl(accountObj?.cf_domain_name, adInfo?.source_asset_id?._id, '480'))
        }

        // send event when ad is playing to remove loading spinner
        player.one('adplaying', function () {
          customRollPlayed = true

          commonAdPlayChanges(adInfo, player)

          // player.play()
          // player.muted(false)

          if (adInfo?.is_skip_allowed) {
            setSkipSeconds(adInfo.skip_duration)
            SKIP_SECONDS = adInfo.skip_duration

            let secondInterval = setInterval(() => {
              if (SKIP_SECONDS > 0) {
                setSkipSeconds((prev) => prev - 1)
                SKIP_SECONDS = SKIP_SECONDS - 1
              }
              if (SKIP_SECONDS === 0) {
                clearInterval(secondInterval)
                return
              }
            }, 1000)
          }
        })

        // resume content when all your linear ads have finished
        player.on('adended', function () {
          player.ads.endLinearAdMode()
          // player.controls(true)
          setCurrentPlayingAd({})
        })
      }
      // player.duration()
    })
  }

  const handleAdDuration = (seconds) => {
    if (!seconds) return
    setAdDuration(seconds)
  }

  useInterval(
    async () => {
      setAdDuration((prev) => prev - 1)
    },
    adDuration > 0 ? 1000 : null,
  )

  const handleSkip = () => {
    if (!playerRef.current) return
    const player = playerRef.current

    player.ads.endLinearAdMode()
    // player.controls(true)
    setCurrentPlayingAd({})
  }

  let positionConfig = {
    TOP_LEFT: 'justify-start items-start ',
    TOP_CENTER: 'justify-center items-start ',
    TOP_RIGHT: 'justify-end items-start ',
    CENTER_LEFT: 'justify-start items-center ',
    CENTER: 'justify-center items-center ',
    CENTER_RIGHT: 'justify-end items-center ',
    BOTTOM_LEFT: { left: '60px' },
    BOTTOM_CENTER: { left: '47%' },
    BOTTOM_RIGHT: {},
  }

  const handleRedirect = (linkObj) => {
    customNavigate('INDIRECT', linkObj, false)
  }

  const getStyleValue = (parentKey, childKey, defaultValue) => {
    if (!currentPlayingAd?.button?.style_config) {
      return defaultValue ? defaultValue : ''
    }

    let tempArr = currentPlayingAd?.button?.style_config[parentKey]?.data.filter(
      (info) => info.key === childKey,
    )

    return !isEmpty(tempArr) ? (tempArr[0].value ? tempArr[0].value : defaultValue) : defaultValue
  }

  const commonAdPlayChanges = (adInfo, player) => {
    setCurrentPlayingAd(adInfo)
    player.trigger('ads-ad-started')
    handleAdDuration(adInfo?.ad_duration)
    setShowSettingMenu(false)
  }

  const handlePlayerReady = (player) => {
    playerRef.current = player

    player.on('waiting', () => {
      console.log('player is waiting')
    })

    player.on('ready', () => {
      player.playsinline(true)
      player.volume(localStorage.getItem('volume') ? localStorage.getItem('volume') : 1)
    })

    player.on('playing', async () => {
      if (isMobile) {
        player.requestFullscreen()
      }
      localStorage.setItem('volume', player.volume())
      setDuration(Math.floor(player.duration()))
      setIsPlaying(true)
    })

    player.on('play', async () => {
      setIsPlaying(true)
    })

    player.on('pause', async () => {
      setIsPlaying(false)
    })

    player.on('timeupdate', function () {
      setCurrentTime(Math.floor(player.currentTime()))
    })

    player.on('fullscreenchange', () => {
      if (player.isFullscreen()) {
        setIsFullScreen(true)
        return
      }
      setIsFullScreen(false)
    })

    player.on('progress', function () {
      const buffPercentage = player.bufferedPercent()
      // console.log('Buffer Percentage: ', buffPercentage * 100)
      setBufferPercent(buffPercentage * 100)
    })

    player.on('enterpictureinpicture', () => {
      setIsPIPMode(true)
    })

    player.on('leavepictureinpicture', () => {
      setIsPIPMode(false)
    })

    player.on('volumechange', () => {
      setVolume(player.volume() * 100)
      localStorage.setItem('volume', player.volume())
    })

    player.on('seeked', async () => {})

    player.on('dispose', async () => {})

    player.on('ended', async () => {})

    player.on('error', () => {})
  }

  const handleSeek = (event) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    let sec = (event.nativeEvent.offsetX / event.target.parentNode.clientWidth) * player.duration()

    player.currentTime(sec)
  }

  const handleSeekOver = (event) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    setSeekBarHoverPercent(event.nativeEvent.offsetX / event.target.parentNode.clientWidth)
  }

  const togglePlayPause = (isPlay) => {
    let player = playerRef.current

    if (!player || !isEmpty(currentPlayingAd)) {
      return
    }
    isPlay ? player.play() : player.pause()
  }

  const toggleFullScreen = (isFull) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    isFull ? player.requestFullscreen() : player.exitFullscreen()
  }

  const togglePIPMode = (isPIP) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    isPIP ? player.requestPictureInPicture() : player.exitPictureInPicture()
  }

  const toggleVolume = (value) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    setIsMute(false)
    player.muted(false)
    player.volume(value / 100)
  }

  const toggleMuted = (status) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    player.volume(status ? 0 : 100)
    player.muted(status)
    setIsMute(status)
  }

  const getVolumeIcon = () => {
    if (volume > 49 && volume <= 100) {
      return VolFullIcon
    } else if (volume > 10 && volume <= 49) {
      return VolLowIcon
    } else {
      return VolZeroIcon
    }
  }

  const handleKeyPress = (e) => {
    let player = playerRef.current
    if (!player) {
      return
    }
    if (e.keyCode === 39) {
      player.currentTime(player.currentTime() + 10)
    }
    if (e.keyCode === 37) {
      player.currentTime(player.currentTime() - 10)
    }

    if (e.keyCode === 38) {
      player.volume(player.volume() < 1 ? player.volume() + 0.1 : 1)
    }
    if (e.keyCode === 40) {
      player.volume(player.volume() > 0 ? player.volume() - 0.1 : 0)
    }
  }

  useInterval(
    async () => {
      if (isPlaying && !showSettingMenu && !cursorOverControlBar) {
        setShowControlBar(false)
      }
    },
    showControlBar && !isMobile ? 4000 : null,
  )

  return (
    <div
      data-vjs-player=""
      className="relative"
      onKeyDown={handleKeyPress}
      tabIndex={-1}
      onMouseMove={() => setShowControlBar(true)}
    >
      <div
        style={{
          //   border: '1px solid red',
          height: 'calc(100%)',
          display: !isEmpty(currentPlayingAd) ? 'flex' : 'none',
        }}
        className="w-full relative flex-col py-4 pl-4 pr-7 z-30"
      >
        {currentPlayingAd?.button?.is_enabled &&
          !currentPlayingAd?.button?.position.includes('BOTTOM') && (
            <div
              className={`w-full h-full flex ${positionConfig[currentPlayingAd?.button?.position]}`}
            >
              <button
                style={{
                  fontSize: '12px',
                  backgroundColor: getStyleValue('button_background', 'color', '#ffd91d'),
                  color: getStyleValue('button_text', 'color', '#000'),
                  fontFamily: getStyleValue('button_text', 'font', ''),
                  textAlign: getStyleValue('button_text', 'alignment', ''),
                  height: '34px',
                }}
                className={`border rounded-full px-4 ${getStyleValue(
                  'button_text',
                  'font_format',
                  '',
                )}`}
                onClick={() => handleRedirect(currentPlayingAd?.button?.link)}
              >
                {currentPlayingAd?.button?.text}
              </button>
            </div>
          )}
        <div
          style={{ marginTop: 'auto', fontSize: '12px' }}
          className="flex justify-between items-center relative"
        >
          {currentPlayingAd?.button?.is_enabled &&
            currentPlayingAd?.button?.position.includes('BOTTOM') && (
              <button
                style={{
                  fontSize: '12px',
                  background: '#ffd91d',
                  height: '34px',
                  zIndex: 20,
                  //   width: 'fit-content',
                  color: '#000',
                  ...positionConfig[currentPlayingAd?.button?.position],
                }}
                className={`border rounded-full px-4 absolute w-auto ${
                  currentPlayingAd?.button?.position === 'BOTTOM_RIGHT'
                    ? currentPlayingAd?.is_skip_allowed
                      ? 'right-[100px]'
                      : 'right-0'
                    : ''
                }`}
                onClick={() => handleRedirect(currentPlayingAd?.button?.link)}
              >
                {currentPlayingAd?.button?.text}
              </button>
            )}
          <div className="flex items-center">
            <p className="">{`Ad `}</p>
            <div
              style={{
                width: '3px',
                height: '3px',
                background: '#fff',
                borderRadius: '12px',
                marginLeft: '3px',
                marginRight: '3px',
              }}
            />
            <p>{AdSecondsConvert(adDuration)}</p>
          </div>

          {currentPlayingAd?.is_skip_allowed && (
            <>
              {skipSeconds > 0 ? (
                <div
                  style={{
                    background: '#00000080',
                    borderRadius: '17px',
                    fontSize: '10px',
                    height: '34px',
                  }}
                  className="px-4 flex justify-center items-center cursor-pointer"
                >
                  <p className="px-1 mr-1" style={{ borderRight: '0.5px solid #e3e3e3' }}>
                    {t('skip_after')}
                  </p>
                  <p>{skipSeconds}</p>
                </div>
              ) : (
                <div
                  style={{
                    background: '#fff',
                    borderRadius: '17px',
                    fontSize: '10px',
                    height: '34px',
                  }}
                  className="px-4 flex text-center justify-center items-center text-black cursor-pointer"
                  onClick={handleSkip}
                >
                  <p className="px-1 mr-1">{t('skip_ad')}</p>
                  <img
                    src={SkipIcon}
                    alt="SkipIcon"
                    style={{
                      width: '9.8px',
                      height: '13px',
                    }}
                  />
                </div>
              )}
            </>
          )}
        </div>

        {/* </div> */}
      </div>

      {!isLive && (
        <div
          style={{ height: 'calc(100% - 54px)' }}
          className="text-white w-full absolute top-0 z-20 flex justify-center items-center"
          onClick={controlBar ? () => togglePlayPause(!isPlaying) : null}
        >
          {!isPlaying ? (
            <img
              src={CentredPlayIcon}
              alt="CentredPlayIcon"
              style={{ marginTop: '54px' }}
              className="z-20 cursor-pointer xl:h-[100px] xl:w-[100px]"
            />
          ) : null}
        </div>
      )}

      <video
        ref={videoRef}
        className={
          isLive
            ? 'video-js vjs-theme-fantasy '
            : 'video-js-custom vjs-fill vjs-big-play-centered vjs-theme-fantasy-custom'
        }
      />
      {isEmpty(currentPlayingAd) && controlBar && duration && (
        <div
          style={{
            height: isMobile || showControlBar ? '54px' : '0px',
            backgroundColor: '#00000080',
            transition: 'height 0.25s ease-in',
          }}
          className="text-white w-full absolute bottom-0 px-2 "
          onMouseOver={() => setCursorOverControlBar(true)}
          onMouseLeave={() => setCursorOverControlBar(false)}
        >
          <div className="parent-div relative w-full">
            <div
              className={`w-full ${!isVODLive && 'cursor-pointer'}  relative`}
              style={{ background: '#6a738380', height: '5px' }}
              onClick={isVODLive ? null : (e) => handleSeek(e)}
              onMouseOver={isVODLive ? null : (e) => handleSeekOver(e)}
              title={
                isVODLive
                  ? null
                  : `${convertVideoSecondstoMintues(Math.floor(seekBarHoverPercent * duration))}`
              }
            >
              {/* <div
                className="absolute font-semibold border"
                style={{
                  background: '#00000080',
                  zIndex: 20,
                  top: '-25px',
                  padding: '6px',
                  left: `calc(${seekBarHoverPercent}% - 3%)`,
                }}
              >
                {convertVideoSecondstoMintues(seekBarHoverPercent * duration)}
              </div> */}
              <div
                className=" absolute h-full left-0 top-0"
                style={{
                  width: isVODLive ? '100%' : `${(currentTime / duration) * 100}%`,
                  background: 'red',
                  zIndex: 20,
                  transition: 'all 300ms ease',
                }}
              />
              <div
                className="absolute h-full left-0 top-0"
                style={{
                  width: `${bufferPercent}%`,
                  background: '#92888680',
                  zIndex: 5,
                }}
              />
            </div>
          </div>
          <div className="w-full flex justify-between mt-2 relative">
            <div className="flex justify-center items-center ">
              <img
                src={isPlaying ? PauseIcon : PlayIcon}
                alt={isPlaying ? 'PauseIcon' : 'PlayIcon'}
                className="w-8 h-8 cursor-pointer"
                title="Pause"
                onClick={() => togglePlayPause(!isPlaying)}
              />
              {handleNext && handlePrev ? (
                <img
                  src={NextIcon}
                  alt={'NextIcon'}
                  className="w-8 h-8 cursor-pointer ml-2 rotate-180"
                  title="Next"
                  onClick={() => {
                    handlePrev()
                  }}
                />
              ) : null}
              {handleNext ? (
                <img
                  src={NextIcon}
                  alt={'NextIcon'}
                  className="w-8 h-8 cursor-pointer mx-2"
                  title="Next"
                  onClick={() => {
                    handleNext()
                  }}
                />
              ) : null}

              <div
                className="flex items-center mx-2"
                onMouseEnter={() => setShowVolume(true)}
                onMouseLeave={() => setShowVolume(false)}
              >
                <img
                  src={isMute ? VolMuteIcon : getVolumeIcon()}
                  alt={isMute ? 'VolMuteIcon' : 'VolumeIcon'}
                  className="w-6 h-6 cursor-pointer"
                  title="Volume"
                  onClick={() => toggleMuted(!isMute)}
                />

                {showVolume && (
                  <input
                    type="range"
                    className={`volume-range `}
                    min={0}
                    max={100}
                    step={2}
                    value={volume}
                    onChange={(event) => {
                      toggleVolume(event.target.valueAsNumber)
                    }}
                    style={{
                      width: `${showVolume ? '70px' : '0px'}`,
                      opacity: `${showVolume ? '0.9' : '0'}`,
                    }}
                  />
                )}
              </div>
              {isVODLive ? (
                <div className="mx-2 text-white flex items-center justify-center">
                  <span className="mt-0.5">LIVE</span>
                  <img
                    src={RedCircle}
                    style={{ width: '10px', height: '10px' }}
                    className="ml-1"
                    alt={'live'}
                  />
                </div>
              ) : (
                <p className="italic mx-2">
                  {`${convertVideoSecondstoMintues(currentTime)} / ${convertVideoSecondstoMintues(
                    duration,
                  )}`}
                </p>
              )}
            </div>
            <div className="flex justify-center items-center lg:relative">
              <div className="lg:mx-2">
                <img
                  src={SettingsIcon}
                  alt="PlayIcon"
                  className=" cursor-pointer"
                  title="Setting"
                  style={{ width: '30px', height: '30px' }}
                  onClick={() => setShowSettingMenu(!showSettingMenu)}
                />
              </div>
              {showSettingMenu && (
                <SettingsMenu playerRef={playerRef} setShowSettingMenu={setShowSettingMenu} />
              )}

              <div className="mx-2 hidden lg:block">
                <img
                  src={isPIPMode ? CaptureIcon : PIPIcon}
                  alt={isPIPMode ? 'CaptureIcon' : 'PIPIcon'}
                  className="w-8 h-8 cursor-pointer"
                  title={isPIPMode ? 'CaptureIcon' : 'PIPIcon'}
                  onClick={() => togglePIPMode(!isPIPMode)}
                />
              </div>
              <div className="mx-2">
                <img
                  src={isFullScreen ? FullscreenExitIcon : FullscreenEnterIcon}
                  alt={isFullScreen ? 'FullscreenExitIcon' : 'FullscreenEnterIcon'}
                  className="w-8 h-8 cursor-pointer"
                  title={isFullScreen ? 'FullscreenExitIcon' : 'FullscreenEnterIcon'}
                  onClick={() => toggleFullScreen(!isFullScreen)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CustomVideoJs
